
import { Config3D, LOAD_TYPE, Utils } from '@bright-spaces/engine-3d/dist/helpers'
import { Client3DManager } from '@bright-spaces/engine-3d/dist/client/Client3DManager'
import {CurrentView, ProjectFocusMode} from '~/store/building/-constants'
import { CLIENT_CONFIG_3D, CLIENTS, ClientUtils } from '~/helpers/ClientUtils'
import OrganismPins from '~/components/organisms/project/OrganismPins.vue'
import baseConstants from '~/store/base/-constants'
import OrganismBuildingLabels
  from '~/components/organisms/project/building/OrganismBuildingLabels.vue'
import { getElementHalfWidth } from '~/helpers/util';
import availabilityConstants from "~/store/availability/-constants";
import projectConstants from "~/store/project/-constants";
import guidedTourConstants from '@/store/guidedTour/-constants'
import AtomContextLostLoading from '~/components/atoms/common/AtomContextLostLoading.vue'

export default {
  name: 'OrganismBuilding',
  components: { OrganismBuildingLabels, OrganismPins, AtomContextLostLoading },
  props: {
    customModel: {
      required: false,
      type: Object,
      default: function () {
        return {}
      }
    }
  },
  data() {
    return {
      windowHeight: typeof window !== 'undefined' ? window.innerHeight : 0,
      sidebarHeight: 0,
      scrollButtonHeight: 0,
      canvasHeight: 0,
      pinData: []
    }
  },
  computed: {
    isInitialized() {
      return this.$store.state.building.isInitialized
    },
    cdnBase() {
      return this.$store.getters.cdnBase
    },
    activeProject() {
      return this.$store.state.project.activeProject
    },
    isFloorView() {
      return this.$store.state.building.currentView === CurrentView.FLOOR
    },
    buildings() {
      return this.$store.state.project.project.buildings
    },
    floors() {
      return this.$store.state.project.project.floors
    },
    generalConfig() {
      return this.$store.state.base.meta.generalConfig
    },
    projectSettings() {
      return this.$store.getters.getActiveProjectSettings
    },
    engine3d() {
      return this.$engine3d || window.engine3d
    },
    pinConfigBuilding() {
      return this.projectSettings?.building?.pinsData || []
    },
    pinConfigFloor() {
      return this.projectSettings?.floor?.pinsData || []
    },
    pinConfig() {
      return this.isFloorView ? this.pinConfigFloor : this.pinConfigBuilding
    },
    htmlPinsEnabled() {
      return this.isFloorView
        ? this.projectSettings?.floor?.htmlPins || false
        : this.projectSettings?.building?.htmlPins || false
    },
    hasPins() {
      if (!this.htmlPinsEnabled) return false
      return this.pinConfig && this.pinConfig.pins && this.pinConfig.pins.length > 0
    },
    defaultFitoutActive() {
      return this.projectSettings.floor.activeFitout
    },
    isMenuOpen() {
      return this.$store.state.base.isAvailabilityMenuOpen
    },
    sliderZoomValue() {
      return this.projectSettings.floor.sliderZoomValue || 0
    },
    projectFocusMode() {
      return this.$store.state.building.projectFocusMode
    },
    isBuildingFocus() {
      return this.projectFocusMode === ProjectFocusMode.BUILDING
    },
    isFloorFocus() {
      return this.projectFocusMode === ProjectFocusMode.FLOOR
    },
    isSpaceFocus() {
      return this.projectFocusMode === ProjectFocusMode.SPACE
    },
    isEmbedPath() {
      return this.$route.path.includes('/embed')
    },
  },
  watch: {
    isInitialized: function (value) {
      if (value) {
        document.body.classList.add('disable-scroll');
      }
    },
    isEmbedPath: function (value) {
      if (value) {

      }
    },
    $route: {
      deep: true,
      handler: function (to, from) {
        this.$nextTick(async () => {
          this.initializeObserver();
        })
      }
    }
  },
  mounted() {
    window.addEventListener('keydown', this.changeBuildingViewOnEscape);
    this.resizeListener();

    this.$nextTick(async () => {
      this.initializeObserver();
    })
  },
  beforeDestroy() {
    this.$store.dispatch('building/destroy')
    this.engine3d && this.engine3d.default().destroy()
    window.removeEventListener('resize', this.resizeListener)
    window.removeEventListener('keydown', this.changeBuildingViewOnEscape)
  },
  methods: {
    changeBuildingViewOnEscape(e) {
      if (['Escape'].includes(e.code)) {
        if (this.isBuildingFocus) {
          this.$store.dispatch(availabilityConstants.withNamespace(availabilityConstants.action.CLEAR_FILTERS));
          this.$store.dispatch(projectConstants.withNamespace(projectConstants.action.RESET_SURFACE_FIELDS));
          this.clearSelectedSpace()
        }
        if (this.isFloorFocus || this.isSpaceFocus) {
          this.$store.dispatch(
            availabilityConstants.withNamespace(availabilityConstants.action.UPDATE_FILTERS),
            {
              floorId: undefined,
              floorNo: undefined,
            }
          );
          this.clearSelectedSpace()
        }
      }
    },
    async clearSelectedSpace() {
      return this.$store.dispatch('building/viewSpace', {})
    },
    async initializeBuilding3D() {
      let highlightValues = []
      if (this.projectSettings.building.highlightValues) {
        highlightValues = this.projectSettings.building.highlightValues
      } else {
        this.buildings.forEach((building) => {
          highlightValues.push({
            building: building.code,
            highlight: {
              highlightAlpha:
                this.projectSettings.building.highlightAlpha || this.projectSettings.building.alpha,
              highlightBeta:
                this.projectSettings.building.highlightBeta || this.projectSettings.building.beta
            }
          })
        })
      }

      const payload = {
        pinsData: this.projectSettings.building.pinsData,
        client: this.$config.CLIENT,
        project: this.$store.state.project.project,
        cdnBase: this.cdnBase,
        activeProject: this.activeProject,
        settings: this.projectSettings
      }
      this.$store.dispatch('building/destroy')
      this.$store.dispatch('building/initBuilding', payload)
      // Init scene
      const defaultConfig3D = CLIENT_CONFIG_3D[this.$config.CLIENT].building
      const modelPath = `objects/${this.$config.CLIENT}/${this.$store.getters.getActiveProjectData.slug}/project`

      let payload3D = {
        ...Client3DManager.DEFAULT_BUILDING_PAYLOAD,
        cdnBase: this.cdnBase,
        activeProject: modelPath,
        ...this.projectSettings.building,
        pinsData: this.pinConfigBuilding.pins,
        pinsContentCDN: this.cdnBase,
        highlightValues
      }

      if (this.projectSettings.building.caseSettings) {
        payload3D = Object.assign(payload3D, this.projectSettings.building.caseSettings)
      }

      const clientManager = this.engine3d.default()
      clientManager.onSceneReady = () => {
        this.$store.commit('building/setLoadingAssetsStatus', true)
        this.$store.commit('building/setLoadingScreenStatus', false)

        clientManager.onAfterRender(() => {
          if (window.innerWidth < 1200) {
            clientManager.showPinsByCategory('none')
          } else {
            clientManager.showPinsByCategory('')
          }
        }, 100)

        if (defaultConfig3D.includes(Config3D.buildingInteractionManager) && this.isMenuOpen) {
          const elementHalfWidth = getElementHalfWidth('#organism-sidebar-project');
          const offsetValueX = (this.isMenuOpen && window.innerWidth > 900) ? -elementHalfWidth : 0;
          const offsetValueY = window.innerWidth <= 900 ? -50 : 0;
          const offsetTarget = { x: offsetValueX, y: offsetValueY };
          clientManager.cameraFocusTo("", "", offsetTarget);
        }
      }
      clientManager.onAssetLoadingError = (message) => {
        if (window && 'analyticsLayer' in window) {
          window.analyticsLayer.send({
            event: 'asset_loading_error',
            payload: {
              location: 'building',
              message
            }
          })
        }
        this.$store.commit('building/setAssetLoadingError', message)
      }
      clientManager.onContextLostEvent = () => {
        if (window && 'analyticsLayer' in window) {
          window.analyticsLayer.send({
            event: 'webgl_context_lost',
            payload: {}
          })
        }
        this.$store.commit('building/setWebGLContextLost', true)
      }
      clientManager.onContextRestoredEvent = () => {
        setTimeout(() => {
          if (window && 'analyticsLayer' in window) {
            window.analyticsLayer.send({
              event: 'webgl_context_restored',
              payload: {}
            })
          }
          this.$store.commit('building/setWebGLContextLost', false)
        }, 5 * 1000)
      }
      clientManager.setSceneNodeNames = () => {
        const sceneNodeNames = {}
        const buildingNames = []
        for (const building of this.buildings) {
          buildingNames.push(building.code.toLowerCase())
        }
        sceneNodeNames.buildingCodeNames = buildingNames

        return sceneNodeNames
      }
      await clientManager.init(payload3D, this.$refs.canvas, defaultConfig3D)
      clientManager.load(LOAD_TYPE.BUILDING)
    },
    async initializeFloor3D(space) {
      if (!space) return
      if (space.tours && space.tours.length) {
        window.vueStore = this.$store
        this.$store.dispatch(
          guidedTourConstants.withNamespace(guidedTourConstants.action.SET_AVAILABLE_TOURS),
          space.tours
        )
      }
      const building = this.buildings.find((b) => b.id === space.building_id)
      const floor = this.floors.find((f) => f.id === space.floor_id)
      const payload = {
        pinsData: this.projectSettings.floor.pinsData,
        space: space.space_code,
        building: space.floor.building.code.toLowerCase(),
        floor: space.floor.code,
        floorType: space.floor.floor_type.model,
        spaceData: space,
        client: this.$config.CLIENT,
        project: this.$store.state.project.project,
        cdnBase: this.cdnBase,
        activeProject: this.activeProject,
        settings: this.projectSettings
      }
      /// Client Utils
      const skyboxSuffix = ClientUtils.getSkyboxPath(this.$config.CLIENT, {
        buildingCode: space.floor.building.code.toLowerCase(),
        floor: space.floor.name
      })
      this.$store.dispatch('building/destroy')
      this.$store.dispatch('building/viewSpace', payload)
      this.$store.dispatch('building/initFloor', payload)
      // Init scene
      const modelPath = `objects/${this.$config.CLIENT}/${this.$store.getters.getActiveProjectData.slug}`

      const payload3D = {
        ...Client3DManager.DEFAULT_FLOOR_PAYLOAD,
        cdnBase: this.cdnBase,
        activeProject: modelPath,
        floorCode: space.floor.floor_type.model.split('.gltf')[0].toLowerCase(),
        startPosition: {
          x: space.camera_position_x,
          y: space.camera_position_y,
          z: space.camera_position_z
        },
        target: {
          x: space.focus_target_x,
          y: space.focus_target_y,
          z: space.focus_target_z
        },
        ...this.projectSettings.floor,
        skyboxTexture: this.projectSettings.floor.skyboxTexture + skyboxSuffix,
        pinsData: this.pinConfigFloor.pins
      }
      const defaultConfig3D = CLIENT_CONFIG_3D[this.$config.CLIENT].floor
      const fitoutNodeName =
        'fitouts_' + payload3D.floorCode + '_' + space.space_code.replace(/;/g, '_')
      const clientManager = this.engine3d.default()
      clientManager.onSceneReady = () => {
        /// Hide measurements node
        if (this.$config.CLIENT === CLIENTS.SKANSKA) {
          Utils.toggleNodes([Utils.findNode('measurement_e2_floor2_11')], false)
        }
        if (defaultConfig3D.includes(Config3D.fitoutsManager)) {
          const fitoutNode = Utils.findNode(fitoutNodeName)
          const fitoutsInfo = this.projectSettings.floor.fitoutsInfo
          this.$store.commit('building/setFitoutData', {
            initialized: true,
            data: ClientUtils.getFitoutNames(fitoutNode, fitoutsInfo) // this method is used to populate the store fitoutData parameter with an array of objects of type {text, value}
          })
        }

        if (!this.defaultFitoutActive) {
          setTimeout(() => {
            clientManager.changeFitout({ id: 'none' })
          }, 1)
        }

        if (this.sliderZoomValue !== 0) {
          clientManager.setZoomValue(100 - this.sliderZoomValue)
        }

        /// Scene is ready to be displayed
        this.$store.commit('building/setLoadingAssetsStatus', true)
      }
      clientManager.onChangeViewMode = (viewmode) => {
        this.$store.dispatch('building/changeViewMode', viewmode)
      }
      clientManager.onAssetLoadingError = (message) => {
        if (window && 'analyticsLayer' in window) {
          window.analyticsLayer.send({
            event: 'asset_loading_error',
            payload: {
              location: 'floor',
              message,
              space
            }
          })
        }
        this.$store.commit('building/setAssetLoadingError', message)
      }
      clientManager.onContextLostEvent = () => {
        if (window && 'analyticsLayer' in window) {
          window.analyticsLayer.send({
            event: 'webgl_context_lost',
            payload: {}
          })
        }
        this.$store.commit('building/setWebGLContextLost', true)
      }
      clientManager.onContextRestoredEvent = () => {
        setTimeout(() => {
          if (window && 'analyticsLayer' in window) {
            window.analyticsLayer.send({
              event: 'webgl_context_restored',
              payload: {}
            })
          }
          this.$store.commit('building/setWebGLContextLost', false)
        }, 5 * 1000)
      }
      clientManager.setSceneNodeNames = () => {
        const sceneNodeNames = {}
        sceneNodeNames.fitouts = fitoutNodeName
        sceneNodeNames.cameraFocusNode = fitoutNodeName
        /// Set camera focus node
        if (
          this.$config.CLIENT === CLIENTS.RIVER ||
          this.$config.CLIENT === CLIENTS.SKANSKA_FINLAND ||
          this.$config.CLIENT === CLIENTS.ADVENTUM
        ) {
          sceneNodeNames.cameraFocusNode = ClientUtils.getLockedFocusNode(
            space.space_code,
            payload3D.floorCode
          )
        }
        if (
          [CLIENTS.SKANSKA, CLIENTS.CAIMMO, CLIENTS.RESOLUTION_PROPERTY].includes(
            this.$config.CLIENT
          )
        ) {
          sceneNodeNames.floorLockedNode = ''
        }

        const spaces = ClientUtils.getSpaces(this.$config.CLIENT, {
          spaceCode: space.space_code,
          floorCode: payload3D.floorCode
        })
        sceneNodeNames.selectedSpaces = spaces.selectedSpaces
        sceneNodeNames.selectedSpaceCodes = spaces.selectedSpaceCodes
        sceneNodeNames.separatorNode = ClientUtils.getSeparatorsNodeName(this.$config.CLIENT, {
          floorCode: payload3D.floorCode
        })

        return sceneNodeNames
      }
      await clientManager.init(payload3D, this.$refs.canvas, defaultConfig3D)
      clientManager.load(LOAD_TYPE.FLOOR)
    },
    resizeListener() {
      this.sidebarHeight = document.getElementById('organism-sidebar-project') ? document.getElementById('organism-sidebar-project').clientHeight : 0
      this.scrollButtonHeight = document.getElementById('scroll-to-next-section') ? document.getElementById('scroll-to-next-section').clientHeight : 0
      this.windowHeight = window.innerHeight
      if (window.innerWidth > 900) {
        this.canvasHeight = this.windowHeight
      } else {
        this.canvasHeight = this.windowHeight - this.sidebarHeight - this.scrollButtonHeight
      }
      this.$nextTick(() => {
        const clientManager = this.engine3d.default()
        if (!clientManager) return
        clientManager.resize()
      })
    },
    initializeObserver() {
      const options = {
        root: null, // Use the viewport as the root element
        rootMargin: '0px', // No margin around the root element
        threshold: 0.9 // Trigger when at least 50% of the element is visible
      }
      const observer = new IntersectionObserver(this.handleIntersection, options)

      // Start observing the section
      if (this.$refs.sectionToObserve) {
        observer.observe(this.$refs.sectionToObserve)
      }
    },
    handleIntersection(entries, observer) {
      // The callback function to handle the intersection
      entries.forEach((entry) => {
        if (entry.isIntersecting) {
          // Section entered the viewport
          this.$store.dispatch(
            baseConstants.withNamespace(baseConstants.action.CHANGE_COLLAPSED_MENU),
            false
          )
        } else {
          // Section left the viewport
          this.$store.dispatch(
            baseConstants.withNamespace(baseConstants.action.CHANGE_COLLAPSED_MENU),
            true
          )
        }
      })
    }
  }
}
